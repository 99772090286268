const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const customParseFormat = require("dayjs/plugin/customParseFormat");
const timezone = require("dayjs/plugin/timezone");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const isBetween = require("dayjs/plugin/isBetween");
const duration = require("dayjs/plugin/duration");
const isoWeek = require("dayjs/plugin/isoWeek");
const advancedFormat = require("dayjs/plugin/advancedFormat");
const localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

/*
Added to support the duration notation without using the duration plugin since it calculates things ... differently.
Usage: dayjs().addPeriod({ years: 3, month: 1, hours: 2 });
*/
const addOrSubtractPeriod = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.addPeriod = function (period) {
    let rvalue = this;
    for (const [key, value] of Object.entries(period)) {
      rvalue = rvalue.add(value, key);
    }
    return rvalue;
  };

  dayjsClass.prototype.subtractPeriod = function (period) {
    let rvalue = this;
    for (const [key, value] of Object.entries(period)) {
      rvalue = rvalue.subtract(value, key);
    }
    return rvalue;
  };
};

dayjs.extend(addOrSubtractPeriod);

module.exports = dayjs;
