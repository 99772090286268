const _ = require("lodash");
const dayjs = require("../api/lib/DayjsExt");
const { getIntl } = require("../api/lib/intlHelper");

class MaintenanceConfig {
  constructor(locale) {
    this.locale = locale;
    this.intl = getIntl(this.locale);
  }

  shouldDisplayHeader() {
    const config = this.getConfig();
    if (!config.enabled) {
      return false;
    }

    const beginOfHeaderWindow = dayjs.unix(config.begin).subtract(config.hoursToShowHeaderInAdvance, "hours");
    const endOfHeaderWindow = config.hardMaintenance ? dayjs.unix(config.begin) : dayjs.unix(config.end);
    return dayjs().isBetween(beginOfHeaderWindow, endOfHeaderWindow, null, "[)");
  }

  defaultConfig() {
    return {
      enabled: false,
      begin: 0,
      end: 0,
      messageDuringWindow: this.intl.formatMessage({
        id: "maintenance.defaultMessageDuringWindow",
        defaultMessage: "Sorry for the inconvenience. We will be back up and running as soon as possible",
        description: "Default maintenance message shown to users while the maintenance is ongoing"
      }),
      messageBeforeWindow: this.intl.formatMessage({
        id: "maintenance.defaultMessageBeforeWindow",
        defaultMessage: "We will be down for maintenance within 24 hours",
        description: "Default maintenance message shown to users before the maintenance"
      }),
      hoursToShowHeaderInAdvance: 24,
      hardMaintenance: false
    };
  }

  getConfig() {
    // Unix timestamps (* 1000 to get them in miliseconds to build a date)
    const begin = 1711432800;
    const end = 1711434000;

    const messageBeforeWindow = dayjs.unix(begin).isSame(dayjs.unix(end), "day")
      ? this.intl.formatMessage(
          {
            id: "maintenance.customMessageDuringWindowOnSameDay",
            defaultMessage:
              "The Q1.6 dashboard will be unavailable on {startDate} between {startTime} and {endTime}. We are sorry for any inconvenience.",
            description: "Custom maintenance message shown to users before the maintenance"
          },
          {
            startDate: this.intl.formatDate(new Date(begin * 1000), {
              weekday: "long",
              month: "short",
              day: "2-digit"
            }),
            startTime: this.intl.formatTime(new Date(begin * 1000)),
            endTime: this.intl.formatTime(new Date(end * 1000), { timeZoneName: "short" })
          }
        )
      : this.intl.formatMessage(
          {
            id: "maintenance.customMessageDuringWindowOnDifferentDay",
            defaultMessage: "The Q1.6 dashboard will be unavailable from {startDate} until {endDate}. We are sorry for any inconvenience.",
            description: "Custom maintenance message shown to users before the maintenance"
          },
          {
            startDate: this.intl.formatDate(new Date(begin * 1000), {
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              timeZoneName: "short"
            }),
            endDate: this.intl.formatDate(new Date(end * 1000), {
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              timeZoneName: "short"
            })
          }
        );
    return _.extend(this.defaultconfig, {
      enabled: false,
      begin,
      end,
      hoursToShowHeaderInAdvance: 120,
      messageBeforeWindow,
      messageDuringWindow: this.intl.formatMessage(
        {
          id: "maintenance.customMessageBeforeWindow",
          defaultMessage: "Sorry for the inconvenience. We will be back up and running on {backOnlineDate} at {backOnlineTime}",
          description: "Custom maintenance message shown to users while the maintenance is ongoing"
        },
        {
          backOnlineDate: this.intl.formatDate(new Date(begin * 1000), {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "2-digit"
          }),
          backOnlineTime: this.intl.formatTime(new Date(end * 1000), { timeZoneName: "short" })
        }
      ),
      hardMaintenance: false
    });
  }

  getHeaderMessage() {
    const config = this.getConfig();

    if (config.hardMaintenance) {
      return config.messageBeforeWindow;
    }

    const beginOfHeaderWindow = dayjs.unix(config.begin).subtract(config.hoursToShowHeaderInAdvance, "hours");
    const endOfHeaderWindow = dayjs.unix(config.begin);

    return dayjs().isBetween(beginOfHeaderWindow, endOfHeaderWindow, null, "[)") ? config.messageBeforeWindow : config.messageDuringWindow;
  }
}

module.exports = { MaintenanceConfig };
