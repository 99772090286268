const { createIntl, createIntlCache } = require("react-intl");

const { getTranslationsMessagesForLanguage } = require("../../lang");
const { defaultLocale, supportedLanguages } = require("../../lang/languageHelper");

const cache = createIntlCache();

// https://github.com/formatjs/formatjs/issues/1441#issuecomment-523512846
// Seems like it is best to maintain 1 instance per language, and use a shared cache
const intlInstancesPerLanguage = supportedLanguages.reduce((instances, lang) => {
  return { ...instances, [`${lang}`]: createIntl({ locale: lang, messages: getTranslationsMessagesForLanguage(lang) }, cache) };
}, {});

const intlHelper = {
  getIntl: (locale = defaultLocale) => {
    // console.info(Object.keys(intlInstancesPerLanguage));
    const intlInstance = intlInstancesPerLanguage[locale];
    // console.info(intlInstance);

    if (!intlInstance) {
      // Maintenance.js prevents logservice from being used
      console.info(`Tried to retrieve intl instance for locale: ${locale}, but failed to find one`);
      return intlInstancesPerLanguage[defaultLocale];
    }

    return intlInstance;
  }
};

module.exports = intlHelper;
