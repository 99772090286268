import React from "react";

const { MaintenanceConfig } = require("../../../../../config/maintenance");

const marginTop = 65;
const style = {
  position: "sticky",
  top: marginTop,
  marginTop,
  zIndex: 1030,
  textAlign: "center",
  marginBottom: 0,
  color: "#ffffff",
  backgroundColor: "#70b2cf",
  padding: "5px 0",
  borderBottom: "1px solid #DDDDDD"
};

const Maintenance = ({ locale }) => {
  const maintenanceConfig = new MaintenanceConfig(locale);
  return maintenanceConfig.shouldDisplayHeader() ? (
    <div style={style}>{maintenanceConfig.getHeaderMessage()}</div>
  ) : (
    <div style={{ marginTop }} />
  );
};

export default Maintenance;
