import React from "react";
import { Layout } from "antd";

import { FormattedMessage } from "react-intl";

const { Footer: AntFooter } = Layout;

function Footer() {
  return (
    <AntFooter style={{ textAlign: "center" }}>
      <FormattedMessage
        id="layout.footer.copyrightMessage"
        defaultMessage="Copyright Q1.6 © 2015-{endYear} - All rights reserved -
        <aTerms>
          Terms of Use & Privacy policy
        </aTerms> -
        <aCookie>
          Cookie policy
        </aCookie>"
        description="Footer message about copyright"
        values={{
          endYear: new Date().getFullYear(),
          aTerms: (chunks) => (
            <a href="https://www.q16.ai/terms-general" target="_blank" rel="noreferrer">
              {chunks}
            </a>
          ),
          aCookie: (chunks) => (
            <a href="https://frisket-public.s3.amazonaws.com/ToU/Cookie/cookie.html" target="_blank" rel="noreferrer">
              {chunks}
            </a>
          )
        }}
      />
    </AntFooter>
  );
}

export default Footer;
