import React from "react";
import _ from "lodash";
import { ConfigProvider } from "antd";
import { useIntl } from "react-intl";

// Italian is not completely translated yet
// import itIT from "antd/lib/locale/it_IT";
// https://ant.design/docs/react/i18n
import enGB from "antd/lib/locale/en_GB";
import enUS from "antd/lib/locale/en_US";
import nlBE from "antd/lib/locale/nl_BE";
import nlNL from "antd/lib/locale/nl_NL";
import frFR from "antd/lib/locale/fr_FR";
import deDE from "antd/lib/locale/de_DE";
import esES from "antd/lib/locale/es_ES";
import itIT from "antd/lib/locale/it_IT";
import plPL from "antd/lib/locale/pl_PL";
import svSE from "antd/lib/locale/sv_SE";
import trTR from "antd/lib/locale/tr_TR";
import daDK from "antd/lib/locale/da_DK";
import roRO from "antd/lib/locale/ro_RO";
import csCZ from "antd/lib/locale/cs_CZ";
import ptPT from "antd/lib/locale/pt_PT";
import hrHR from "antd/lib/locale/hr_HR";

// RTL
import heIL from "antd/lib/locale/he_IL";
import arEG from "antd/lib/locale/ar_EG";

// Custom ant translations
import getTranslations from "../../../../lang/antd/default";

const AntLocaleWrapper = ({ children, language = "en-GB" }) => {
  const intl = useIntl();

  const antDLocales = {
    "en-GB": enGB,
    "en-US": enUS,
    "nl-BE": nlBE,
    "nl-NL": nlNL,
    "es-ES": esES,
    "fr-FR": frFR,
    "de-DE": deDE,
    "pl-PL": plPL,
    "he-IL": heIL,
    "ar-AE": arEG,
    "ar-KW": arEG,
    "sv-SE": svSE,
    "tr-TR": trTR,
    "da-DK": daDK,
    "ro-RO": roRO,
    "cs-CZ": csCZ,
    "pt-PT": ptPT,
    "hr-HR": hrHR
  };

  const rtlLocales = ["he-IL", "ar-AE", "ar-KW"];

  const determineDirection = () => {
    return _.includes(rtlLocales, language) ? "rtl" : "ltr";
  };

  const getAntDConfigForLanguage = () => {
    // https://github.com/ant-design/ant-design/issues/23369
    if (language === "it-IT") {
      return _.defaultsDeep(itIT, getTranslations(intl));
    }
    if (language === "da-DK") {
      return _.defaultsDeep(daDK, getTranslations(intl));
    }
    if (language === "cs-CZ") {
      return _.defaultsDeep(csCZ, getTranslations(intl));
    }
    if (language === "pt-PT") {
      return _.defaultsDeep(ptPT, getTranslations(intl));
    }
    return antDLocales[language] ? antDLocales[language] : enGB;
  };

  return (
    <ConfigProvider locale={getAntDConfigForLanguage()} direction={determineDirection()}>
      {children}
    </ConfigProvider>
  );
};

export default AntLocaleWrapper;
