import React from "react";
import ReactDOM from "react-dom";

import { FormattedMessage } from "react-intl";
import { AppLayout } from "./components/layout";

ReactDOM.render(
  <AppLayout viewModel={viewModel.appLayout} viewContext={viewModel.context}>
    <>
      <div style={{ minHeight: "400px" }}>
        <FormattedMessage
          id="homepage.welcomeMessage"
          defaultMessage="Homepage of the onepointsixseconds API."
          description="Welcome message for the users on our homepage"
        />
      </div>
    </>
  </AppLayout>,
  document.getElementById("root")
);
